import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import theme from './theme/theme'
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import ScrollToTopNavigate from './components/ScrollToTopNavigate';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <ThemeProvider theme={theme}> 
  <BrowserRouter>
  <ScrollToTopNavigate></ScrollToTopNavigate>
  <App />
  </BrowserRouter>
  </ThemeProvider>
  
   
  </React.StrictMode>
);
