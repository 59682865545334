import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { config } from '../../../config/index'
import parse from 'html-react-parser';
import "./news.css"
import { RWebShare } from "react-web-share";

const { DIRECTUS_API_ASSET_URL } = config
const NewsContent = (props) => {
    const url = window.location.href
    return (
        <>
            <Grid container justifyContent='center'>
                <Grid item xs={11} sm={8} xl={6}>

                    <Typography sx={{ marginBottom: '20px', fontWeight: 'medium', fontSize: '30px', color: 'primary.typo' }}>{props.news.title}</Typography>
                    <Box>
                        <img src={DIRECTUS_API_ASSET_URL + props.news.cover_image} style={{ width: '100%', borderRadius: '20px' }}></img>
                    </Box>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={0}
                    >
                        <Typography sx={{ fontStyle: 'italic' }}>
                            Adapted from: {props.news.adpated_from}
                        </Typography>

                        <Typography sx={{ fontStyle: 'italic' }}>
                            Posted on: {props.news.date}
                        </Typography>

                    </Stack>
                    <RWebShare
                        data={{
                            text: "View this exciting article",
                            url: url,
                            title: "Share this article"
                        }}
                        onClick={() => console.info("share successful!")}
                    >
                       <Button  variant="contained" sx={{width:'10%',backgroundColor: 'customYellow.main', color: 'white', mt:'20px'}}>Share</Button>
                    </RWebShare>

                    <Typography id='newsId' sx={{ color: 'primary.typo', fontSize: '20px', marginTop: '50px' }}>
                        {parse(props.news.main_body)}
                    </Typography>

                   
                </Grid>
            </Grid>

        </>
    )
}

export default NewsContent