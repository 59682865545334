import React, { useState, useEffect, createContext } from 'react'
import Loader from '../../Loader'
import { fetchProjects } from '../../../utils/Fetch'
import { ProjectContext } from '../ProjectContext'
import Main from '../Main'
const LocalProject = () => {
    const [isLoading, setLoading] = useState(true)
    const [projects, setProjects] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchProjects('Local').catch(err => {alert('network error, contact adminstrator');console.log(err)})
            console.log(response)
            setProjects(response.data)
       
            setLoading(false)
        }
        fetchData()
    }, [])

    return (
        <>
            {isLoading ? <Loader /> : <ProjectContext.Provider value={projects}><Main type='Local'></Main></ProjectContext.Provider>}
        </>
    )
}
export default LocalProject