import { config } from '../config/index'

const { DIRECTUS_API_URL } = config


const fetchProjects= async (type)=>{
    let url = DIRECTUS_API_URL+`project/?fields=display_images.*&fields=*&filter[local_international_setting]=${type}&filter[status]=published`
    console.log(url)
    const response = await fetch(url)
    if(!response.ok){
        const message = `An error occured: ${response.status}`;
        throw new Error(message);
    }
    const data= await response.json()
    return data
}

const fetchProjectsBanner= async ()=>{
    let url = DIRECTUS_API_URL+`project_banner`

    const response = await fetch(url)
    if(!response.ok){
        const message = `An error occured: ${response.status}`;
        throw new Error(message);
    }
    const data= await response.json()
    return data
}

const fetchNews = async()=>{
    
    let url = DIRECTUS_API_URL+`news/?fields=*&filter[status]=published`
  
    const response = await fetch(url)
    if(!response.ok){
        const message = `An error occured: ${response.status}`;
        throw new Error(message);
    }
    const data= await response.json()
    return data
}

const fetchSingleNews = async(id)=>{
    let url = DIRECTUS_API_URL+`news/${id}`
    const response = await fetch(url)
    if(!response.ok){
        const message = `An error occured: ${response.status}`;
        throw new Error(message);
    }
    const data= await response.json()
    return data
}

const fetchSingleProject = async(id)=>{
    let url = DIRECTUS_API_URL+`project/${id}?fields=display_images.*&fields=*`
    const response = await fetch(url)
    if(!response.ok){
        const message = `An error occured: ${response.status}`;
        throw new Error(message);
    }
    const data= await response.json()
    return data
}



export{fetchProjects, fetchSingleProject, fetchNews,fetchProjectsBanner,fetchSingleNews}
    