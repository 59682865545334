import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { config } from '../../../config/index'

const { DIRECTUS_API_ASSET_URL } = config

   
const ProjectDescription = (props) => {
    return (
        <>
            <Stack spacing={3}>
                <Box>
                    <Carousel
                        showThumbs={false}
                        preventMovementUntilSwipeScrollTolerance={true}
                        swipeScrollTolerance={50}
                        infiniteLoop={true}
                        autoPlay={false}
                        showArrows={false}
                        transitionTime={300}
                        stopOnHover={false}
                        emulateTouch={true}
                        showIndicators={false}
                        showStatus={false}
                    >
                        {
                        props.project.display_images.map((display_img)=>
                        <img src={DIRECTUS_API_ASSET_URL+display_img.directus_files_id} style={{maxHeight:'460px'}}></img>)
                        }
                        
                    </Carousel>
                </Box>
                <Stack spacing={2}>
                    <Typography sx={{fontSize:'23px', fontWeight:'bold',color:'primary.typo'}}>
                        {props.project.name}
                    </Typography>
                    <Typography sx={{fontSize:'17px', color:'primary.typo'}}>
                        {props.project.description}
                    </Typography>
                </Stack>
            </Stack>

        </>
    )
}

export default ProjectDescription