import { createTheme } from '@mui/material';

const theme = createTheme({
    palette: {
        type: 'light',
        primary: {
          main: '#083D21',
          secondary:'#FFFFFF',
          typo: '#374049',
          grey:'#878787'
        },
        secondary: {
          main: '#f50057',
        },
        customYellow :{
            main: '#D4AF37'
        }
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 999,
          md: 1000,
          lg: 1275,
          xl: 1536,
        }
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            '&:hover': {
              color:'white'
            },
          }),
        },
      },
        MuiMenu:{
            styleOverrides:{
                list :{
                    backgroundColor:'#D4AF37',
                    color:'white'
                },
               
            }
        }
    }
})

export default theme