import React, { useState, useEffect} from 'react'

import { fetchProjectsBanner } from '../../utils/Fetch'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {useContext} from 'react'
import {ProjectContext} from './ProjectContext'
import Box from '@mui/material/Box';
import ProjectBannerBox from './ProjectBannerBox'

const ProjectBanner = ()=>{
    const projects = useContext(ProjectContext)

    const [projectsBanner, setProjectsBanner] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchProjectsBanner().catch(err => alert('network error, contact adminstrator'))
            setProjectsBanner(response.data)
            console.log(response)
        }
        fetchData()
    }, [])

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 1
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

return(<>
        <Box sx={{height: { xs: 700, sm: 720 }}}>
         <Carousel 
                responsive={responsive}
                autoPlay={true}
                autoPlaySpeed={4000}
                infinite
            >
            
            {projectsBanner.map((project)=><ProjectBannerBox project={project}></ProjectBannerBox>)}

     

            </Carousel>
</Box>
</>
    
    )
}

export default ProjectBanner