import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import investmentPng from '../../storage/Assets/Homepage/investmentManagement.png'
import divestmentPng from '../../storage/Assets/Homepage/DivestmentManagement.png'
import devPng from '../../storage/Assets/Homepage/DevManagement.png'
import assetPng from '../../storage/Assets/Homepage/assetManagement.png'
import relocationJpg from '../../storage/Assets/Homepage/ResourceManagement.png'
import { useNavigate } from "react-router-dom";
import { Slide } from "react-awesome-reveal";

const HomeCardsComponent = () => {
    const navigate = useNavigate()
    const handleButtonClick = (link) => {
        navigate('/' + link)
    }
    return (
        <>
            <Grid spacing={3} container justifyContent='center' sx={{ backgroundColor: 'primary.main', pt: '30px', pb: "30px", flexDirection: { xs: 'row', sm: 'row' }, pl: { xs: '0px', sm: '0px' }, mb: '120px' }}>

                <Grid item xs={12} sm={10} sx={{
                    m: { xs: '0 auto', sm: '0' }, width: { xs: '100%', sm: '100%' }, textAlign: { xs: 'center', sm: 'center' }, height: 'fit-content', justifyContent: 'center',
                    alignSelf: 'center'
                }}>
                    <Slide direction="left" triggerOnce={true} duration={1200}>
                        <Stack spacing={0} sx={{ textAlign: { xs: 'center', sm: 'center' } }}>
                            <Typography sx={{ color: 'white', display: { xs: 'block', sm: 'block' }, fontSize: { xs: '45px', sm: '60px' }, fontWeight: 'bold', lineHeight: 1.2 }}>
                                Property  Business   Management,
                            </Typography>

                            <Typography sx={{ color: '#D4AF37' , fontSize: { xs: '35px', sm: '60px' }, fontWeight: 'bold', lineHeight: 1.2 }}>
                          Re-engineered
                            </Typography>

                            <Typography sx={{ color: 'white', fontSize: { xs: '12px', sm: '15px' }, fontWeight: 'light', margin: '0 auto', mt: '15px', width: { xs: '70%', sm: '30%' }, textAlign: 'center' }}>
                            Our diverse estate agency and management services make planning, buying, managing and selling 
property a smooth and transparent process for all stakeholders.           </Typography>
                        </Stack>
                        <Button variant="contained" sx={{ width: { xs: 158, sm: 189 }, backgroundColor: 'customYellow.main', mt: '15px' }} onClick={() => handleButtonClick('About')}>
                            DISCOVER MORE
                        </Button>
                    </Slide>
                </Grid>

                <Grid item xs={12} sm={10} sx={{ mt: { xs: '40px', sm: 0 } }}>
                    <Slide direction="right" triggerOnce={true} duration={1200}>
                        <Box sx={{ width: '100%', height: { sm: 'fit-content' }, borderRadius: '15px 0px 0px  15px' }}>
                            <Grid columns={12}  spacing={4} container sx={{ flexDirection: { xs: 'row', sm: 'row' }, pt: '14px', pb: '24px' }} justifyContent='center' alignItems="center">
                                <Grid item xs={10} sm={4} >
                                    <Paper sx={{ textAlign: 'center', width: '100%', height: { xs: '250px', sm: '390px', lg: '300px' }, backgroundColor: 'customYellow.main', borderRadius: '15px' }}>
                                        <Grid container spacing={1} sx={{ pl: '20px', pt: '20px', pr: '20px', pb: '20px' }}>
                                            <Grid item xs={12}>
                                                <img src={investmentPng} style={{ width: '65px' }}></img>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Typography sx={{ color: 'primary.main', fontSize: { xs: '20px', sm: '30px' }, fontWeight: 'bold', lineHeight: 1.1 }}>
                                                    Investment
                                                </Typography>
                                                <Typography sx={{ color: 'primary.main', fontSize: { xs: '20px', sm: '30px' }, fontWeight: 'bold', lineHeight: 1.1 }}>
                                                    Management
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Typography sx={{ color: 'primary.secondary', fontSize: { xs: '12px', sm: '15px' }, fontWeight: 'regular' }}>
                                                    Management includes devising a short- or long-term strategy for acquiring and disposing of portfolio holdings. It can also include banking, budgeting, and tax services and duties.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                                <Grid item xs={10} sm={4}>
                                    <Paper sx={{ textAlign: 'center', width: '100%', height: { xs: '250px', sm: '390px', lg: '300px' }, backgroundColor: 'customYellow.main', borderRadius: '15px' }}>
                                        <Grid container spacing={1} sx={{ pl: '20px', pt: '20px', pr: '20px', pb: '20px' }}>
                                            <Grid item xs={12}>
                                                <img src={devPng} style={{ width: '65px' }}></img>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Typography sx={{ color: 'primary.main', fontSize: { xs: '20px', sm: '30px' }, fontWeight: 'bold', lineHeight: 1.1 }}>
                                                    Development
                                                </Typography>
                                                <Typography sx={{ color: 'primary.main', fontSize: { xs: '20px', sm: '30px' }, fontWeight: 'bold', lineHeight: 1.1 }}>
                                                    Management
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Typography sx={{ color: 'primary.secondary', fontSize: { xs: '12px', sm: '15px' }, fontWeight: 'regular' }}>
                                                    Development management is the strategic planning, administration and controlling of a project during its development life cycle, from project planning through to construction completion and project exit.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>

                                <Grid item xs={10} sm={4}>
                                    <Paper sx={{ textAlign: 'center', width: '100%', height: { xs: '250px', sm: '390px', lg: '300px' }, backgroundColor: 'customYellow.main', borderRadius: '15px' }}>
                                        <Grid container spacing={1} sx={{ pl: '20px', pt: '20px', pr: '20px', pb: '20px' }}>
                                            <Grid item xs={12}>
                                                <img src={relocationJpg} style={{ width: '65px' }}></img>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Typography sx={{ color: 'primary.main', fontSize: { xs: '20px', sm: '30px' }, fontWeight: 'bold', lineHeight: 1.1 }}>
                                                    Relocation
                                                </Typography>
                                                <Typography sx={{ color: 'primary.main', fontSize: { xs: '20px', sm: '30px' }, fontWeight: 'bold', lineHeight: 1.1 }}>
                                                    Services
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Typography sx={{ color: 'primary.secondary', fontSize: { xs: '12px', sm: '15px' }, fontWeight: 'regular' }}>
                                                    We are committed to delivering exceptional
                                                    moving services. We work with a worldwide
                                                    network to ensure the relocation process is
                                                    as stress-free as possible, through our
                                                    wide range or services.         </Typography>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                                <Grid item xs={10} sm={4}>
                                    <Paper sx={{ textAlign: 'center', width: '100%', height: { xs: '250px', sm: '390px', lg: '300px' }, backgroundColor: 'customYellow.main', borderRadius: '15px' }}>
                                        <Grid container spacing={1} sx={{ pl: '20px', pt: '20px', pr: '20px', pb: '20px' }}>
                                            <Grid item xs={12}>
                                                <img src={assetPng} style={{ width: '65px' }}></img>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Typography sx={{ color: 'primary.main', fontSize: { xs: '20px', sm: '30px' }, fontWeight: 'bold', lineHeight: 1.1 }}>
                                                    Asset
                                                </Typography>
                                                <Typography sx={{ color: 'primary.main', fontSize: { xs: '20px', sm: '30px' }, fontWeight: 'bold', lineHeight: 1.1 }}>
                                                    Management
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Typography sx={{ color: 'primary.secondary', fontSize: { xs: '12px', sm: '15px' }, fontWeight: 'regular' }}>
                                                    We help clients increase their total wealth over time by acquiring, maintaining, and trading investments that have the potential to grow in value.    </Typography>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                                <Grid item xs={10} sm={4} >
                                    <Paper sx={{ textAlign: 'center', width: '100%', height: { xs: '250px', sm: '390px', lg: '300px' }, backgroundColor: 'customYellow.main', borderRadius: '15px' }}>
                                        <Grid container spacing={1} sx={{ pl: '20px', pt: '20px', pr: '20px', pb: '20px' }}>
                                            <Grid item xs={12}>
                                                <img src={divestmentPng} style={{ width: '65px' }}></img>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Typography sx={{ color: 'primary.main', fontSize: { xs: '20px', sm: '30px' }, fontWeight: 'bold', lineHeight: 1.1 }}>
                                                    Divestment
                                                </Typography>
                                                <Typography sx={{ color: 'primary.main', fontSize: { xs: '20px', sm: '30px' }, fontWeight: 'bold', lineHeight: 1.1 }}>
                                                    Management
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Typography sx={{ color: 'primary.secondary', fontSize: { xs: '12px', sm: '15px' }, fontWeight: 'regular' }}>
                                                    Our divestment strategy involves a company selling off a portion of its assets at an opportunistic time, often to improve company value and obtain higher efficiency.     </Typography>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Box>
                    </Slide>
                </Grid>

            </Grid>
        </>
    )
}

export default HomeCardsComponent