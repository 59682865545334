import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { config } from '../../../config/index'
import { fetchNews } from '../../../utils/Fetch'
import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
const { DIRECTUS_API_ASSET_URL } = config


  
const NewsBoxes = (props) => {
    const navigate = useNavigate()
    const handleButtonClick = (id) => {
        navigate('/News/' + id)
    }
    const [allNews, setAllNews] = useState([])
    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchNews().catch(err => alert('network error, contact adminstrator'))
            console.log(response)
            setAllNews(response.data)

        }
        fetchData()
    }, [])

    let recent = allNews.sort((a, b) => b.date_created - a.date_created)
    // let banner = DIRECTUS_API_ASSET_URL + NewsCover.cover_image
    if (recent.length > 3) {
        recent = recent.slice(0, 3)
    }
    return (
        <>
 <Typography sx={{width:'95%',margin:'0 auto', marginTop:'30px',color: 'customYellow.main', textDecoration: 'underline', fontSize: '40px', fontWeight: 'bold' }}>Worth the read</Typography>
    
        <Grid container direction="row"
                        justifyContent="left"
                        spacing={12}
                        sx={{ mb: '120px', mt: '0px', }}
                    >
            
                {recent.map((topNew) => <Grid item xs={12} sm={4} >  <Box sx={{ width: '90%', margin: '0 auto', height: '100%', backgroundColor: 'customYellow.main', color: 'white', border: '2px solid #D4AF37', borderRadius: '20px', pt: '30px' }}>
                    <Stack spacing={3} sx={{ width: '90%', margin: '0 auto', height: '100%' }}>
                        <img src={DIRECTUS_API_ASSET_URL + topNew.cover_image} style={{ width: '100%' }}></img>
                        <Typography sx={{ color: 'white', fontSize: '20px', fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'noWrap' }}>
                            {topNew.title}
                        </Typography>
                        <Typography sx={{ fontSize: '16px' }}>
                            {topNew.short_description}
                        </Typography>


                        <Button onClick={() => handleButtonClick(topNew.id)} variant="contained" sx={{ marginTop: 'auto !important', mr: '30px', width: 'fit-content', backgroundColor: 'white', color: 'black', mb: '30px !important' }} >
                            View More
                        </Button>

                    </Stack>
                </Box> </Grid>)}




            </Grid>

        </>
    )
}

export default NewsBoxes