const navItems = [
    // {
    //     "id": 1,
    //     "name": "about",
    //     "link": "About",
    //     "value": "About",
    //     "pathName": "/About",
    //     "child": null
    // },

    {
        "id": 1,
        "name": "home",
        "link": "",
        "value": "Home",
        "pathName": "/",
        "child": null
    },

    {
        "id": 4,
        "name": "projects",
        "link": "Projects",
        "value": "Projects",
        "pathName": "/Project",
        "child": [
            {
                "id": 5,
                "name": "local_projects",
                "link": "Local_Projects",
                "value": "Local",
                "child": null
            },
            // {
            //     "id": 6,
            //     "name": "international_projects",
            //     "link": "International_Projects",
            //     "value": "International",
            //     "child": null
            // },
        ]
    },
    {
        "id": 2,
        "name": "events",
        "link": "Events",
        "value": "Events",
        "pathName": "/Events",
        "child": null,
    },
    {
        "id": 3,
        "name": "news",
        "link": "News",
        "value": "News",
        "pathName": "/News",
        "child": null
    },
    // {
    //     "id": 7,
    //     "name": "resources",
    //     "link": "Resources",
    //     "value": "Resources",
    //     "pathName": "/Resources",
    //     "child": null
    // },
  


]

export default navItems