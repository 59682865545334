const ENV = process.env.REACT_APP_ENV;

const appConfig = {
    local:{
        REACT_APP_GOOGLE_CALENDER_API: process.env.REACT_APP_GOOGLE_CALENDAR_API,
        ENV,
        DIRECTUS_API_URL:'https://allianceconnect.directus.app/items/',
        DIRECTUS_API_ASSET_URL:'https://allianceconnect.directus.app/assets/',
        REACT_APP_EMAILJS_SERVICE_ID:process.env.REACT_APP_EMAILJS_SERVICE_ID,
        REACT_APP_EMAILJS_TEMPLATE_ID:process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        REACT_APP_EMAILJS_PUBLIC_KEY:process.env.REACT_APP_EMAILJS_PUBLIC_KEY
    },
    production: {
        REACT_APP_GOOGLE_CALENDER_API: process.env.REACT_APP_GOOGLE_CALENDAR_API,
        ENV,
        DIRECTUS_API_URL:'https://allianceconnect.directus.app/items/',
        DIRECTUS_API_ASSET_URL:'https://allianceconnect.directus.app/assets/',
        REACT_APP_EMAILJS_SERVICE_ID:process.env.REACT_APP_EMAILJS_SERVICE_ID,
        REACT_APP_EMAILJS_TEMPLATE_ID:process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        REACT_APP_EMAILJS_PUBLIC_KEY:process.env.REACT_APP_EMAILJS_PUBLIC_KEY
    }
};

export const config = {...appConfig[ENV]};