import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { config } from '../../config/index'
import {Slide,Fade} from  "react-awesome-reveal";
import { useNavigate } from "react-router-dom";
import { compareByFieldSpec } from '@fullcalendar/react';
const { DIRECTUS_API_ASSET_URL } = config

const NewsTopStories = (props) => {
    const navigate = useNavigate()
    const handleButtonClick = (id) => {
        navigate('/News/' + id)
    }
    
    const NewsCover = props.news.filter((news) => news.top_story == true)
    let newsCarousel
    let topNews
    let trending = props.news.filter((news) => news.trending == 'true')
    let recent = props.news.sort((a, b) => b.date_created - a.date_created)
    // let banner = DIRECTUS_API_ASSET_URL + NewsCover.cover_image
    if (recent.length > 4) {
        recent = recent.slice(0, 4)
    }
    if (trending.length > 4) {
        trending = trending.slice(0, 4)
    }
    if (NewsCover.length > 7) {
        newsCarousel = NewsCover.slice(0, 4)
        topNews = NewsCover.slice(3, 3)
    }
    else {
        newsCarousel = NewsCover
        topNews = NewsCover
    }   

    



    return (
        <>
            <Grid container sx={{ width: '90%', margin: '0 auto', mt: '120px', mb: '80px' }} justifyContent={{ xs: 'center', sm: 'space-between' }}>
                <Grid item xs={11} sm={6} justifyContent='center' alignItems='center' sx={{ mb: { xs: '80px', sm: '0' } }}>
                    <Typography sx={{ width: 'fit-content', margin: '0 auto', fontSize: { xs: '15px', sm: '18px' }, fontWeight: 'bold', textDecoration: 'underline', color: 'customYellow.main', mb: '15px' }}>
                        TOP STORIES
            </Typography>
        
                    <Carousel
                        showThumbs={false}
                        preventMovementUntilSwipeScrollTolerance={true}
                        swipeScrollTolerance={50}
                        infiniteLoop={true}
                        autoPlay={true}
                        showArrows={false}
                        showIndicators={false}
                        transitionTime={300}
                        stopOnHover={false}
                        emulateTouch={true}

                    >

                        {newsCarousel.map((newsCover) =>

                            <Box sx={{ display: { xs: 'block', sm: 'flex' }, alignItems: { xs: 'center', sm: 'flex-end' }, height: '100%', width: '100%', backgroundImage: `url(${DIRECTUS_API_ASSET_URL + newsCover.cover_image})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} >
                                <Stack spacing={2} sx={{ height: { xs: '100%', sm: 'fit-content' }, textAlign: { xs: 'center', sm: 'left' }, padding: '20px', width: { xs: '40%', sm: '100%' }, background: 'rgba(0,0,0,0.7)', color: 'white', justifyContent: 'center' }}>
                                    <Typography sx={{ fontSize: '20px', fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'noWrap' }}>
                                        {newsCover.title}
                                    </Typography>
                                    <Typography sx={{ fontSize: '15px', fontWeight: 'normal' }}>
                                        {newsCover.short_description}
                                    </Typography>
                                    <Button variant="contained" onClick={()=>handleButtonClick(newsCover.id)} sx={{ height: '40px', fontWeight: 'bold', backgroundColor: 'customYellow.main' }}>
                                        Read More
                            </Button>
                                </Stack>
                            </Box>
                        )
                        }

                    </Carousel>
    

                </Grid>
                <Grid item xs={11} sm={5} sx={{ display: { xs: 'flex', sm: 'none' } }}>
                    <Grid container direction="row"
                        justifyContent="left"
                        spacing={12}
                        sx={{ mb: '120px', mt: '0px', display: { xs: 'flex', sm: 'none' } }}
                    >
                        {topNews.map((topNew) => <Grid item xs={12} sm={4} >  <Box sx={{ width: '90%', margin: '0 auto', height: '100%',backgroundColor:'customYellow.main', color:'white', border: '2px solid #D4AF37', borderRadius: '20px', pt: '30px' }}>
                            <Stack spacing={3} sx={{ width: '90%', margin: '0 auto', height: '100%' }}>
                                <img src={DIRECTUS_API_ASSET_URL + topNew.cover_image} style={{ width: '100%' }}></img>
                                <Typography sx={{ color: 'white', fontSize: '20px', fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'noWrap' }}>
                                    {topNew.title}
                                </Typography>
                                <Typography sx={{ fontSize: '16px' }}>
                                    {topNew.short_description}
                                </Typography>


                                <Button  onClick={()=>handleButtonClick(topNew.id)} variant="contained" sx={{ marginTop: 'auto !important', mr: '30px', width: 'fit-content', backgroundColor: 'white',color:'black', mb: '30px !important' }} >
                                    View More
            </Button>

                            </Stack>
                        </Box> </Grid>)}


                    </Grid>
                </Grid>
                <Grid item xs={11} sm={5}>
                    <Typography sx={{ width: 'fit-content', margin: '0 auto', fontSize: { xs: '15px', sm: '18px' }, fontWeight: 'bold', textDecoration: 'underline', color: 'customYellow.main', mb: '15px' }}>

                        MOST VIEWED
            </Typography>
            
                    <Box sx={{ height: '100%' }}>
                        <Stack sx={{ height: '100%', justifyContent: 'space-between' }} spacing={{xs:5, sm:0}}>
                            {trending.map((news) => <Slide direction="right" triggerOnce={true} duration={1200}><Grid container justifyContent='space-between' sx={{ backgroundColor:'customYellow.main',color:'white',border: '2px solid #D4AF37', borderRadius: '20px', padding: '20px' }}>
                                <Grid item xs={12} sm={3} sx={{ minHeight:'200px',backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundImage: `url(${DIRECTUS_API_ASSET_URL + news.cover_image})` }}>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Stack spacing={1} justifyContent='center' sx={{height:'100%'}}>
                                        <Typography sx={{ color: 'white', fontSize: '20px', fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'noWrap' }}>
                                            {news.title}
                                        </Typography>
                                        <Typography sx={{ color: 'white', fontSize: '15px', fontWeight: 'normal' }}>
                                            {news.short_description}
                                        </Typography>
                                        <Button onClick={()=>handleButtonClick(news.id)} variant="contained" sx={{ height: '40px', fontWeight: 'bold', backgroundColor: 'white', color:'black' }}>
                                            Read More
                            </Button>
                                    </Stack>
                                </Grid>
                            </Grid></Slide>)}
                        </Stack>
                   
                    </Box>
                 
                </Grid>
            </Grid>
            <Fade triggerOnce={true} duration={1200}>
            <Grid container direction="row"
                justifyContent="left"
                spacing={12}
                sx={{ mb: '120px', mt: '80px', display: { xs: 'none', sm: 'flex' } }}
            >   
          
                {topNews.map((topNew) => <Grid item xs={12} sm={4} >  <Box sx={{ width: '90%', margin: '0 auto', height: '100%',backgroundColor:'customYellow.main', color:'white', border: '2px solid #D4AF37', borderRadius: '20px', pt: '30px' }}>
                    <Stack spacing={3} sx={{ width: '90%', margin: '0 auto', height: '100%' }}>
                        <img src={DIRECTUS_API_ASSET_URL + topNew.cover_image} style={{ width: '100%' }}></img>
                        <Typography sx={{ color: 'white', fontSize: '20px', fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'noWrap' }}>
                            {topNew.title}
                        </Typography>
                        <Typography sx={{ fontSize: '16px' }}>
                            {topNew.short_description}
                        </Typography>


                        <Button variant="contained" onClick={()=>handleButtonClick(topNew.id)} sx={{ marginTop: 'auto !important', mr: '30px', width: 'fit-content', backgroundColor: 'white', color:'black', mb: '30px !important' }} >
                            View More
            </Button>

                    </Stack>
                </Box> 
              
                </Grid>)}
        
            </Grid>
            </Fade>
        </>
    )
}

export default NewsTopStories