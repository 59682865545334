
import AboutHeader1 from '../../storage/Assets/AboutPage/AboutHeader1.png'
import AboutHeaderLogo from '../../storage/Assets/AboutPage/AboutHeaderLogo.png'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { List, ListItem } from '@mui/material';
import { Slide, Fade } from "react-awesome-reveal";
const AboutHeader = () => {
    return (
        <Grid container direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ width: '90%', margin: '0 auto', mb: '120px' }}
        >
            <Grid item xs={6}>
                <Typography sx={{ fontSize: { xs: '25px', sm: '40px' }, fontWeight: 'bold', fontStyle: 'italic', textDecoration: 'underline', color: 'customYellow.main', mb: '15px' }}>
                    What set us apart
                </Typography>

            </Grid>
            <Grid item xs={8}>
                <Typography sx={{ fontSize: { xs: '25px', sm: '28px' }, textAlign: 'center', fontStyle: 'italic', color: 'primary.main', mb: '15px' }}>
                    “At Alliance Connect, we journeyed and realised our potential together.”

                </Typography>
            </Grid>

            {/* <Grid item xs={11} sm={6} sx={{ mb: '15px' }}>
            <Fade  triggerOnce={true} duration={1200}>
                <img style={{ width: '100%' }} src={AboutHeader1} />
            </Fade>
            </Grid> */}
            <Grid item xs={9} sm={6}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="center" alignItems="center" sx={{ width: { xs: '100%', sm: '80%', lg: '60%' }, margin: '0 auto' }}>

                    <Fade triggerOnce={true} duration={1200}>
                        <Typography sx={{ fontSize: { xs: '17px', sm: '19px', textAlign: 'center' }, color: 'primary.typo' }}>
                            It is an experience to deal with real estate, we are here to make the expereience smooth & delightful. We do that with an organized team of leaders & doers that are experience in many aspect of the real estate sector.     </Typography>
                    </Fade>
                </Stack>

            </Grid>


            <Grid container direction={{ xs: 'column-reverse', sm: 'row' }} sx={{ mt: '80px' }} justifyContent="space-around" spacing={3}>
                {/* <Grid item xs={6} >
                <Slide direction="left" triggerOnce={true} duration={1200}>
                    <Box sx={{ height: { xs: '500px', sm: '650px',lg:'548px' }, width: '100%', border: '3px solid #D4AF37', backgroundColor: 'customYellow.main', borderRadius: '15px' }}>
                  
                        <Box sx={{ width: '85%', margin: '0 auto', pt: '50px' }}>
                            <Stack spacing={2}>
                                <Typography sx={{fontSize:{xs:'35px',sm:'50px'},fontWeight:'bold', color:'primary.main'}}>
                                    Top 5 EA
                                </Typography>
                                <Typography sx={{fontSize:{xs:'15px',sm:'18px'}, color:'primary.secondary'}}>
                                Our Competitors have a systematic & top down process which might not favour those below.
                                </Typography>
                                <List sx={{ listStyleType: 'disc', fontSize:{xs:'18px',sm:'20px'}, color:'primary.secondary'} }>
                                <ListItem sx={{ display: 'list-item',listStylePosition:'inside', pl:0}}>
                                Employed management staff
                                </ListItem>
                                <ListItem sx={{ display: 'list-item',listStylePosition:'inside',pl:0 }}>
                                Multi-layered hierarchy resulting in high overriding
                                </ListItem>
                                <ListItem sx={{ display: 'list-item',listStylePosition:'inside',pl:0 }}>
                                Management commission unknown to downstream
                                </ListItem>
                                <ListItem sx={{ display: 'list-item',listStylePosition:'inside',pl:0 }}>
                                Decentralised training by respective team leaders
                                </ListItem>

                                </List>
                            </Stack>
                        </Box>
                       
                    </Box>
                    </Slide>
                </Grid> */}
                <Grid item xs={6} >
                    <Slide direction="right" triggerOnce={true} duration={1200}>

                        <Box sx={{ height:'fit-content', width: '100%', border: '3px solid #D4AF37', borderRadius: '15px', position: 'relative' }}>
                            <Box sx={{ width: '85%', margin: '0 auto', pt: '50px',pb:'50px' }}>
                                <Stack spacing={2}>
                                    <Typography sx={{ fontSize: { xs: '35px', sm: '50px' }, fontWeight: 'bold', color: 'primary.main' }}>
                                        Alliance Connect
                                    </Typography>
                                    <Typography sx={{ fontSize: { xs: '15px', sm: '18px' }, color: 'primary.typo' }}>
                                        We strive to be a transparent, inclusive and efficient platform at all level of our businesses through:

                                    </Typography>
                                    <List sx={{ listStyleType: 'disc', fontSize: { xs: '18px', sm: '20px' }, color: 'primary.typo' }}>
                                        <ListItem sx={{ display: 'list-item', listStylePosition: 'inside', pl: 0 }}>
                                            Involvement by direct business owners who are licenced Estate Agents
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item', listStylePosition: 'inside', pl: 0 }}>
                                            Lean organisational hierarchy ensure versatility and fast decision making
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item', listStylePosition: 'inside', pl: 0 }}>
                                            Highly trained and experienced staff for prompt , reliable and trustworthy service call
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item', listStylePosition: 'inside', pl: 0 }}>
                                            Transparent commission structures
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item', listStylePosition: 'inside', pl: 0 }}>
                                            Centralised training and development programs to maintain a high standard and
                                            uniformity in standard of services
                                        </ListItem>

                                    </List>
                                </Stack>
                            </Box>
                            <Box sx={{ position: 'absolute', width: '140px', height: '39px', right: '-15px', top: '-19px', backgroundColor: 'customYellow.main', borderRadius: '15px' }}>

                                <Stack
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{ height: '100%' }}
                                >
                                    <Typography sx={{ color: 'primary.secondary', fontSize: '13px', fontWeight: 'bold' }}>
                                        FAVOURED
                                    </Typography>
                                </Stack>
                            </Box>
                        </Box>
                    </Slide>
                </Grid>
            </Grid>


        </Grid>
    )
}

export default AboutHeader