import ScrollTop from '../../scrollTop/ScrollTop'
import ContactForm from '../../contact/ContactForm'
import Footer from '../../footer/Footer'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ProjectDetails from './ProjectDetails'
import ProjectDescription from './ProjectDescription'
const Main = (props) => {
    return (
        <>
            <Grid container direction='row' justifyContent="center" alignItems="flex-start" spacing={3} sx={{mt:'120px', mb:'120px'}}>
            <Grid item xs={11} sm={6}> 
                <ProjectDescription project={props.project}>
                </ProjectDescription>
            </Grid>
            <Grid item xs={11} sm={5}> 
                <ProjectDetails project={props.project }>
                </ProjectDetails>
            </Grid>
            </Grid>

            <ContactForm></ContactForm>
            <Footer></Footer>
            <ScrollTop >
            </ScrollTop>

        </>
    )
}

export default Main
