import React,{useState} from 'react'
import Calender from '../calender/Calender'
import Stack from '@mui/material/Stack';
import Footer from '../footer/Footer'
import Box from '@mui/material/Box';
import ScrollTop from '../scrollTop/ScrollTop'
import ContactForm from '../contact/ContactForm'
const Events = ()=>{

    return(
        <React.Fragment>
            <Box > 
            <Stack>
            <Box sx={{width:'90%', m:'0 auto', mt:'100px', mb:'100px'}}>
            <Calender></Calender>
            </Box>
            <ContactForm></ContactForm>
            <Footer></Footer>
            </Stack>
            </Box>
            <ScrollTop> </ScrollTop>
        </React.Fragment>
    )
}
export default Events