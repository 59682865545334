import React,{useState,useEffect} from 'react'
import {fetchNews} from '../../utils/Fetch'
import Loader from '../Loader'
import Footer from '../footer/Footer'
import ContactForm from '../contact/ContactForm'
import ScrollTop from '../scrollTop/ScrollTop'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button'
import Main from './Main'
const News = ()=>{
    const [news,setNews] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(()=>{
        const fetchData = async () => {
            const response = await fetchNews().catch(err => alert('network error, contact adminstrator'))
            console.log(response)
            setNews(response.data)
            setLoading(false)
        }
        fetchData()
    },[])
    return(
       loading ? <Loader></Loader>:<Main news={news}></Main>
    )
}
export default News