import { useParams } from "react-router-dom";
import React, {useState, useEffect} from 'react'
import {fetchSingleProject} from '../../../utils/Fetch'
import Loader from '../../Loader'
import Main from './Main'
const SingleProject = ()=>{
    let [project, setProject] = useState()
    let [loading, setLoading] = useState(true)
    let params = useParams()
    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchSingleProject(params.id).catch(err => alert('network error, contact adminstrator'))
            console.log(response)
            setProject(response.data)
            setLoading(false)
        }
        fetchData()
    }, [])

    return(
        loading ? <Loader/> : <Main project = {project}></Main> 
    )
}

export default SingleProject