import ScrollTop from './scrollTop/ScrollTop';
import Footer from './footer/Footer';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';


const TermsCondition = () => {
    return (
        <>
            <Grid container direction='row' justifyContent="center" alignItems="flex-start" spacing={3} sx={{ mt: '120px', mb: '120px' }}>
                <Grid item xs={11} sm={11}>
                    <Grid container justifyContent='center'>
                        <Grid item xs={11} sm={8} xl={6}>

                            <Typography sx={{ marginBottom: '20px', fontWeight: 'bold', fontSize: '35px', color: 'primary.typo' }}>Environment Policy</Typography>

                            <Typography sx={{ color: 'primary.typo', fontWeight: 'medium', fontSize: '20px', marginTop: '40px', marginBottom: '20px' }}>
                                Statement
                            </Typography>

                            <Typography sx={{ color: 'primary.typo', fontSize: '17px', marginBottom: '15px' }}>
                                AllianceConnect recognize its duty to comply with relevant Environmental / Sustainability Management legislation.
                            </Typography>
                            <Typography sx={{ color: 'primary.typo', fontSize: '17px', marginBottom: '15px' }}>
                                AllianceConnect also believe that we have a responsibility to care for and protect the environment in which we operate. We are fully committed to improving environmental and sustainability performance across all of our business activities and will encourage our business partners and members of the wider community to join us in this effort.
                            </Typography>

                            <Typography sx={{ color: 'primary.typo', fontSize: '17px', marginBottom: '30px' }}>
                                We are committed not only to complying with applicable law in all of its operations but to minimise risks and impacts through the development of robust and documented systems to implement, measure, monitor, and disseminate excellent environmental performance both within its operations and to the broader community.
                            </Typography>

                            <Typography sx={{ color: 'primary.typo', fontWeight: 'medium', fontSize: '20px', marginTop: '40px', marginBottom: '20px' }}>
                                Objectives
                            </Typography>

                            <Typography sx={{ color: 'primary.typo', fontSize: '17px', marginBottom: '10px' }}>
                                We aim to:
                            </Typography>
                            <ol style={{ listStyleType: 'disc' }}>
                                <li>
                                    Give used material eg office equipment a second life
                                </li>
                                <li>
                                    Consider paperless, unless legally required to do otherwise
                                </li>
                                <li>
                                    Constantly look for environmentally friendly solutions (Transport, Energy & Water)
                                </li>
                                <li>
                                    Inform and educate AllianceConnect team members on the full potential of environmental sustainability
                                </li>
                                <li>
                                    Learn ways to reducing environmental impact
                                </li>
                                <li>
                                    Keep sustainability in mind and continuously improve our environmental performance
                                </li>
                            </ol>

                            <Typography sx={{ marginBottom: '20px', marginTop: '60px', fontWeight: 'bold', fontSize: '35px', color: 'primary.typo' }}>SOCIAL & DEI Responsibility</Typography>

                            <Typography sx={{ color: 'primary.typo', fontSize: '17px', marginBottom: '15px' }}>
                                We believe Corporate Social Responsibility is not an obligation but our nature. Our work is for the betterment for our families, societies, our countries and the world as a whole     </Typography>


                            <Typography sx={{ color: 'primary.typo', fontSize: '17px', marginBottom: '15px' }}>
                                Corporate Social Responsibility starts with our employees, our stakeholders,  our clients, our suppliers and our societies. That means we conduct our business in the highest standard of honesty, professionalism and integrity with mutual respect, trust and care.
                            </Typography>

                            <Typography sx={{ marginBottom: '20px', marginTop: '60px', fontWeight: 'bold', fontSize: '35px', color: 'primary.typo' }}>BUSINESS & ETHICS</Typography>
                            <Typography sx={{ color: 'primary.typo', fontWeight: 'medium', fontSize: '20px', marginTop: '40px', marginBottom: '20px' }}>
                                Business Conduct Quick Test
                            </Typography>
                            <Typography sx={{ color: 'primary.typo', fontSize: '17px', marginBottom: '15px' }}>
                                AllianceConnect endeavor to see that all members adheres to a high standard of social and ethical responsibilities. If in doubt, AllianceConnect Team Members are asked to carry out the <span style={{ fontStyle: 'italic' }}>Business Conduct Quick Test</span> by asking themselves these simple questions:
                            </Typography>
                            <ol style={{ listStyleType: 'disc', marginBottom: '30px' }}>
                                <li style={{ fontWeight: 'bold', marginBottom: '20px' }}>
                                    THE VALUE TEST: Does it fit in with <span style={{ fontStyle: 'italic' }}>Our AllianceConnect values </span>
                                </li>
                                <li style={{ fontWeight: 'bold', marginBottom: '20px' }}>
                                    THE SAFETY TEST: Could it directly or indirectly endanger someone or cause injury?
                                </li>
                                <li style={{ fontWeight: 'bold', marginBottom: '20px' }}>
                                    THE LEGAL TEST: Is it legal and in line with CEA policies and procedures?     </li>
                                <li style={{ fontWeight: 'bold', marginBottom: '20px' }}>
                                    THE CONSCIENCE TEST: Does it fit with my personal values?      </li>
                                <li style={{ fontWeight: 'bold', marginBottom: '20px' }}>
                                    THE NEWSPAPER TEST: If the story appeared in the paper, would i feel comfortable with my decision
                                </li>
                                <li style={{ fontWeight: 'bold', marginBottom: '20px' }}>
                                    THE FAMILY TEST: What would i tell my partner, parent or child to do?
                                </li>
                                <li style={{ fontWeight: 'bold', marginBottom: '20px' }}>
                                    THE 'FEEL' TEST: What's my intuition or 'gut feel'? If it 'feels bad', then it probably is!             </li>
                            </ol>
                            <Typography sx={{ color: 'primary.typo', fontSize: '17px', marginBottom: '15px' }}>
                                Failing any of the above 'tests' indicates that you need to talk with someone about your concerns
                            </Typography>




                            <Typography sx={{ marginBottom: '20px', marginTop: '60px', fontWeight: 'bold', fontSize: '35px', color: 'primary.typo' }}>DEI</Typography>

                            <Typography sx={{ color: 'primary.typo', fontSize: '17px', marginBottom: '15px' }}>
                                AllianceConnect promote inclusion, diversity and fairness in our workplace, below are principles guidance on DEI and I hope the Company’s inclusion and diversity commitments are understood through constant communication; like this avenue.  </Typography>


                            <Typography sx={{ color: 'primary.typo', fontWeight: 'medium', fontSize: '20px', marginTop: '40px', marginBottom: '20px' }}>
                                Gender
                            </Typography>

                            <Typography sx={{ color: 'primary.typo', fontSize: '17px', marginBottom: '15px' }}>
                                Gender equality and opportunity in the workplace and to creating greater gender balance are important. We do recognize that companies with gender balanced teams perform better and at the same time, we recognize that we do not have gender balance  in the organization; as such, we strive to be able to attract, develop and retain male talent in our organization.
                            </Typography>


                            <Typography sx={{ color: 'primary.typo', fontWeight: 'medium', fontSize: '20px', marginTop: '40px', marginBottom: '20px' }}>
                                Multi-Culture
                            </Typography>
                            <Typography sx={{ color: 'primary.typo', fontSize: '17px', marginBottom: '15px' }}>
                                In multi-ethnic society like in Singapore, we strongly believe in embracing different cultures.  It is good for the company too;  through the diversity of our colleagues, we can better understand customers’ needs and build deeper, longer-lasting relationships.
                            </Typography>

                            <Typography sx={{ color: 'primary.typo', fontWeight: 'medium', fontSize: '20px', marginTop: '40px', marginBottom: '20px' }}>
                                Disability & Mental Health
                            </Typography>
                            <Typography sx={{ color: 'primary.typo', fontSize: '17px', marginBottom: '15px' }}>
                                We endeavour to make our office and work environment suitable for all people. We also recognize that not all disabilities are visible and actively work to support those colleagues who may also have a hidden disability or be facing mental health issues.  We  ensure everyone has the same career opportunities and remove barriers that restrict the progress of those that are disabled or those with mental health issues.
                            </Typography>


                            <Typography sx={{ color: 'primary.typo', fontWeight: 'medium', fontSize: '20px', marginTop: '40px', marginBottom: '20px' }}>
                                LGBTQ+
                            </Typography>
                            <Typography sx={{ color: 'primary.typo', fontSize: '17px', marginBottom: '15px' }}>
                                We ensure that everyone can bring their whole self to work, irrespective of their sexual orientation, gender, identity or expression.  We respect our colleagues’ privacy and their choice to share as they wish.  The Company (Both AW & AllianceConnect) is committed to a safe workplace where identity in any way has no impact on a person’s day-to-day experiences and interactions, career or progression.


                            </Typography>

                            <Typography sx={{ color: 'primary.typo', fontWeight: 'medium', fontSize: '20px', marginTop: '40px', marginBottom: '20px' }}>
                                Socio-Economic
                            </Typography>
                            <Typography sx={{ color: 'primary.typo', fontSize: '17px', marginBottom: '15px' }}>
                                We commit to socioeconomic diversity and supporting social mobility by making career opportunities available to those from underprivileged backgrounds. We endeavour to:-
                            </Typography>
                            <ol>
                                <li>
                                    Ensure job descriptions are as open as possible and in everyday language to make them accessible to all socio-economic backgrounds
                                </li>
                                <li>
                                    Actively question whether a university degree is a requirement for any role that we advertise
                                </li>
                                <li>Build awareness of early careers and recruit employees away from the traditional recruitment channels </li>
                            </ol>


                            <Typography sx={{ color: 'primary.typo', fontWeight: 'medium', fontSize: '20px', marginTop: '40px', marginBottom: '20px' }}>
                                Multi-Generation
                            </Typography>
                            <Typography sx={{ color: 'primary.typo', fontSize: '17px', marginBottom: '15px' }}>
                                We want to be an employer of choice for our colleagues at all life stages and provide all employees with career development opportunities regardless of age.
                            </Typography>
                            <Typography sx={{ color: 'primary.typo', fontSize: '17px', marginBottom: '15px' }}>
                                Hope we all can provide a pleasant, safe and supportive environment for each and every one in the community and not only for our colleagues.</Typography>




                            <Typography sx={{ marginBottom: '20px', marginTop: '60px', fontWeight: 'bold', fontSize: '35px', color: 'primary.typo' }}>Governance</Typography>

                            <Typography sx={{ color: 'primary.typo', fontWeight: 'medium', fontSize: '20px', marginTop: '40px', marginBottom: '20px' }}>
                              Code of Business Conduct
                            </Typography>

                            <Typography sx={{ color: 'primary.typo', fontSize: '17px', marginBottom: '15px' }}>
                            We are committed to upholding ethical business practices. The various KEOs in AllianceConnect Group are responsible for the ethnics and culture of AllianceConnect and all associates and staff have a responsibility to support the KEOs in upholding a high standard of business conduct. Each potential member will be thoroughly screened and seconded before joining the group
                            </Typography>

                            <Typography sx={{ color: 'primary.typo', fontWeight: 'medium', fontSize: '20px', marginTop: '40px', marginBottom: '20px' }}>
                              Integrity 
                            </Typography>

                            <Typography sx={{ color: 'primary.typo', fontSize: '17px', marginBottom: '15px' }}>
                            Doing what is right means conducting ourselves with integrity in every transaction. This helps to earn the trust and respect of the people we work with inside and outside the agency. Each of us has the responsibility to uphold the Group's reputation by acting ethically
                            </Typography>
                            <Typography sx={{ color: 'primary.typo', fontWeight: 'medium', fontSize: '20px', marginTop: '40px', marginBottom: '20px' }}>
                              Respect
                            </Typography>

                            <Typography sx={{ color: 'primary.typo', fontSize: '17px', marginBottom: '15px' }}>
                            Treating our clients, assignees and co-workers in a professional and respectful manner.
                            </Typography>
                            <Typography sx={{ color: 'primary.typo', fontWeight: 'medium', fontSize: '20px', marginTop: '40px', marginBottom: '20px' }}>
                              Performance
                            </Typography>

                            <Typography sx={{ color: 'primary.typo', fontSize: '17px', marginBottom: '15px' }}>
                            Always strive to improve ourselves and give value-added service. In each assignment we undertake, to ensure that excellent delivery of service is provided. We strive for excellence and actively solicit feedback from clients on how to service them bettwe and ways to improve. 
                            </Typography>
                            <Typography sx={{ color: 'primary.typo', fontWeight: 'medium', fontSize: '20px', marginTop: '40px', marginBottom: '20px' }}>
                              Accountability
                            </Typography>

                            <Typography sx={{ color: 'primary.typo', fontSize: '17px', marginBottom: '15px' }}>
                            Taking ownership, accepting responsibilities and delivery on our commitments. KEOs and managers will also be held accountable not only for their own business conduct, but also that of their associates or business partners.
                            </Typography>

                            <Typography sx={{ color: 'primary.typo', fontWeight: 'bold',fontStyle:'italic', fontSize: '20px', marginTop: '40px', marginBottom: '20px' }}>
                              This is our competitive advantage
                            </Typography>




                        </Grid>
                    </Grid>

                </Grid>
            </Grid>

            <Footer></Footer>
            <ScrollTop >
            </ScrollTop>
        </>
    )
}

export default TermsCondition