import ScrollTop from '../../scrollTop/ScrollTop'
import ContactForm from '../../contact/ContactForm'
import Footer from '../../footer/Footer'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import NewsBoxes from './NewsBoxes';
import NewsContent from './NewsContent';
const Main = (props) => {
    return (
        <>
            <Grid container direction='row' justifyContent="center" alignItems="flex-start" spacing={3} sx={{mt:'120px', mb:'120px'}}>
            <Grid item xs={11} sm={11}> 
                <NewsContent news={props.news}>
                </NewsContent>
            </Grid>
            <Grid item xs={11} sm={11}> 
                <NewsBoxes news={props.news }>
                </NewsBoxes>
            </Grid>
            </Grid>

            <ContactForm></ContactForm>
            <Footer></Footer>
            <ScrollTop >
            </ScrollTop>

        </>
    )
}

export default Main