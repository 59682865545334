import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import investmentPng from '../../storage/Assets/Homepage/investmentManagement.png'
import divestmentPng from '../../storage/Assets/Homepage/DivestmentManagement.png'
import devPng from '../../storage/Assets/Homepage/DevManagement.png'
import assetPng from '../../storage/Assets/Homepage/assetManagement.png'
import Link from '@mui/material/Link'
import { useNavigate } from "react-router-dom";


const Footer = () => {
    const navigate = useNavigate()
    const handleButtonClick=(link)=>{
        navigate('/'+link)
    }
    return (
        <>
            <Box sx={{ pt: '30px', pb: '30px', height: 'fit-content', backgroundColor: 'primary.main', color:'primary.secondary'}}  id="test-contact-scroll" >
                <Grid container justifyContent="space-around" spacing={5}>
                    <Grid item xs={10} sm={1}>
                        <Grid container direction='column' spacing={1}>
                            <Grid item>
                                <Typography sx={{fontSize:'30px', fontWeight:'bold',textDecoration:'underline'}}>
                                    Links
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Link onClick={()=>handleButtonClick('')} component="button" sx={{color:'primary.secondary',fontSize:'15px', fontWeight:'regular' }}>
                                    Homepage
                        </Link>
                            </Grid>
                       
                            <Grid item>
                                 <Link onClick={()=>handleButtonClick('Local_Projects')}  component="button" sx={{color:'primary.secondary',fontSize:'15px', fontWeight:'regular' }}>
                                    Projects
                        </Link>
                            </Grid>
                            <Grid item>
                                 <Link onClick={()=>handleButtonClick('News')}  component="button" sx={{color:'primary.secondary',fontSize:'15px', fontWeight:'regular' }}>
                                    News
                        </Link>
                            </Grid>
                            <Grid item>
                                 <Link onClick={()=>handleButtonClick('Events')}  component="button" sx={{color:'primary.secondary',fontSize:'15px', fontWeight:'regular' }}>
                                    Events
                        </Link>
                            </Grid>
                        </Grid>
                    </Grid>



                    <Grid item xs={10} sm={1}>
                        <Grid container direction='column' spacing={1}>
                            <Grid item>
                            <Typography sx={{fontSize:'30px', fontWeight:'bold',textDecoration:'underline'}}>
                                    Socials
                        </Typography>
                            </Grid>
                            <Grid item>
                                 <Link component="button" sx={{color:'primary.secondary',fontSize:'15px', fontWeight:'regular' }}>
                                    Instagram
                        </Link>
                            </Grid>
                            <Grid item>
                                 <Link component="button" sx={{color:'primary.secondary',fontSize:'15px', fontWeight:'regular' }}>
                                    Facebook
                        </Link>
                            </Grid>
                            <Grid item>
                                 <Link component="button" sx={{color:'primary.secondary',fontSize:'15px', fontWeight:'regular' }}>
                                    Whatsapp
                        </Link>
                            </Grid>

                        </Grid>
                    </Grid>




                    <Grid item xs={10} sm={3} md={2} >
                        <Grid container direction='column' spacing={1}>
                            <Grid item>
                            <Typography sx={{fontSize:'30px', fontWeight:'bold',textDecoration:'underline'}}>
                                    Admin
                        </Typography>
                            </Grid>
                            <Grid item>
                                 <Link component="button" sx={{color:'primary.secondary',fontSize:'15px', fontWeight:'regular' }}>
                                    Privacy Policy
                        </Link>
                            </Grid>
                            <Grid item>
                                 <Link onClick={()=>handleButtonClick('Terms')} component="button" sx={{color:'primary.secondary',fontSize:'15px', fontWeight:'regular' }}>
                                    Terms & Conditions
                        </Link>
                            </Grid>
                           

                        </Grid>
                    </Grid>


                    <Grid item xs={10} sm={3}>
                        <Grid container direction='column' spacing={2}>
                            <Grid item> 
                            <Button variant="contained" sx={{width:'100%',backgroundColor:'customYellow.main'}} onClick={()=>handleButtonClick('Local_Projects')}> 
                                VIEW OUR PROJECTS</Button>
                            </Grid>
                            <Grid item> 
                            <Button variant="contained" sx={{width:'100%',backgroundColor:'customYellow.main'}} onClick={()=>handleButtonClick('News')}> 
                                SEE OUR NEWS</Button>
                            </Grid>
                            <Grid item> 
                            <Button variant="contained" sx={{width:'100%',backgroundColor:'customYellow.main'}} onClick={()=>handleButtonClick('Events')}> 
                                CHECK OUR UPCOMING EVENTS</Button>
                            </Grid>

                        </Grid>

                    </Grid>

                </Grid>

            </Box>


        </>
    )
}

export default Footer