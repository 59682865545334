import React, { useState } from 'react';
import Box from '@mui/material/Box';
import navItems from './NavItems/index'
import NavAppBar from './NavAppBar'
import NavDrawer from './NavDrawer'

function NavBar(props) {

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [nav, setNav] = useState(navItems)

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <>
        <NavAppBar handleDrawerToggle={handleDrawerToggle}> </NavAppBar>
        <Box component="nav">
        <NavDrawer mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle}></NavDrawer>
        </Box>
        </>
    );
}


export default NavBar;
