import React,{useState} from 'react'

const Resources = ()=>{

    return(
        <React.Fragment>
            resources
        </React.Fragment>
    )
}
export default Resources