import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { config } from '../../../config/index'
import {details} from './details'
import Location from '../../../storage/Assets/Project/location.png'
// import FloorPlan from '../../../storage/Assets/Project/floor_plan.png'
// import SitePLan from '../../../storage/Assets/Project/site_plan.png'
const { DIRECTUS_API_ASSET_URL } = config
const ProjectDetails = (props)=>{
  
    return(<> 
    <Stack spacing={3} >
                <Stack spacing={1} sx={{border:'2px solid #D4AF37', borderRadius:'20px', height:'fitContent', padding:'30px'}}>
                    <Grid  container direction="row"   justifyContent="space-between" alignItems="center" sx={{pb:'10px',borderBottom:'1px solid black'}}> 
                        <Stack sx={{textAlign:'left'}}>
                            <Typography sx={{fontSize:'18px', fontWeight:'bold',color:'primary.typo'}}> 
                            Developer:
                            </Typography>
                            <Typography sx={{fontSize:'15px', fontWeight:'medium',color:'primary.typo'}}> 
                            {props.project.developer_name}
                            </Typography>
                            <Typography sx={{fontSize:'18px', fontWeight:'bold',color:'primary.typo'}}> 
                            Marketing Agency Name:
                            </Typography>
                            <Typography sx={{fontSize:'15px', fontWeight:'medium',color:'primary.typo'}}> 
                            {props.project.Marketing_Agency_Name}
                            </Typography>
                            <Typography sx={{fontSize:'18px', fontWeight:'bold',color:'primary.typo'}}> 
                            License Number
                            </Typography>
                            <Typography sx={{fontSize:'15px', fontWeight:'medium',color:'primary.typo'}}> 
                            {props.project.licence_number}
                            </Typography>
                        </Stack>
                        <Button variant="contained" sx={{width: '140px', backgroundColor: 'customYellow.main'}} onClick={()=>window.open(props.project.developer_contact, "_blank")}>Contact</Button>
                    </Grid>
                      <Stack spacing={2} justifyContent='center'> 
                    {details.map((detail)=><Stack direction='row' justifyContent='space-between' alignItems='center'><Typography sx={{fontSize:'18px', fontWeight:'bold',color:'primary.typo'}}>{detail.value}:</Typography><Typography sx={{fontSize:'13px', fontWeight:'bold',color:'primary.typo'}}>{props.project[detail.key]}</Typography></Stack>)}
                </Stack>
                </Stack>
                <Stack spacing={2}>
                <Button variant="contained" sx={{borderRadius:'10px',height:'50px',width: '100%', backgroundColor: 'primary.main'}} onClick={()=>window.open(props.project.website_link, "_blank")}><Stack direction='row' alignItems='center' spacing={2}><img src={Location} style={{height:'20px', width:'14px'}}></img><Typography sx={{fontSize:'15px', fontWeight:'bold'}}>VIEW PROJECT WEBSITE</Typography> </Stack></Button>
    
                {/* <Button variant="contained" sx={{borderRadius:'10px',height:'50px',width: '100%', backgroundColor: 'customYellow.main'}} onClick={()=>window.open(DIRECTUS_API_ASSET_URL+props.project.floor_plan, "_blank")}><Stack direction='row' alignItems='center' spacing={2}><img src={FloorPlan} style={{height:'20px', width:'14px'}}></img><Typography  sx={{fontSize:'15px', fontWeight:'bold'}}>DOWNLOAD FLOOR PLAN</Typography></Stack></Button>
    
                <Button variant="contained" sx={{borderRadius:'10px',height:'50px',width: '100%', backgroundColor: 'customYellow.main'}} onClick={()=>window.open(DIRECTUS_API_ASSET_URL+props.project.site_plan, "_blank")}><Stack direction='row' alignItems='center' spacing={2}><img src={SitePLan} style={{height:'20px', width:'14px'}}></img><Typography  sx={{fontSize:'15px', fontWeight:'bold'}}>DOWNLOAD SITE PLAN</Typography></Stack></Button>
     */}
                </Stack>
            </Stack>
    </>)
}

export default ProjectDetails