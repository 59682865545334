import './App.css';
import Navbar from './components/navbar/Navbar'
import Box from '@mui/material/Box';
import Home from './components/home/AlternateHome'
import Contact from './components/contact/Contact'
import Events from './components/events/Events'
import About from './components/about/About'
import Resources from './components/resources/Resources';
import News from './components/news/News'
import SingleNews from './components/news/singleNews/SingleNews';
import LocalProject from './components/projects/localProject/LocalProject'
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Toolbar from '@mui/material/Toolbar';
import SingleProject from './components/projects/singleProject/SingleProject'
import ReactGA from 'react-ga';
import React, {useEffect } from 'react';
import TermsCondition from './components/Terms_Condition';
  const TRACKING_ID = "UA-240822068-1"; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);
function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <Box sx={{ display: 'flex', height:'100%'}} id="test-scroll">
      <Navbar></Navbar>
   
      <Box component="main" sx={{ p:0,mt:'67px',width:'100%'}}>

      <Routes>
        <Route path="/" element={<Home />}></Route>
        {/* <Route path="About" element={<About />}></Route> */}
        <Route path="Contact" element={<Contact/>}></Route>
        <Route path="Events" element={<Events />}></Route>
        <Route path="Resources" element={<Resources/>}></Route>
        <Route exact path="News" element={<News/>}></Route>
        <Route exact path="News/:id" element ={<SingleNews/>}></Route>
        <Route path="Local_Projects" element={<LocalProject/>}></Route>
        <Route path="Project/:id" element ={<SingleProject/>}></Route>
        <Route path="Terms" element ={<TermsCondition></TermsCondition>}></Route>
        <Route path="International_Projects" element={<LocalProject/>}></Route>
        <Route path="*"  element={
            <main style={{ padding: "1rem" }}>
              <p>There's nothing here!</p>
            </main>
          }
        ></Route>
      </Routes>
      </Box>
    </Box>

  );
}

export default App;
