
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import map from '../../storage/Assets/Homepage/map.png'
import teamwork from '../../storage/Assets/Homepage/teamwork.png'
import { Slide } from "react-awesome-reveal";
const HomeListComponent=(props)=>{
    const ListOfAgencies=['Asian Homesearch Pte Ltd','Avant Garde Realty Pte Ltd','Asiapac Real Estate Services Pte Ltd','Cellini Property Consultants Pte Ltd','Century 21 Pte Ltd','Everton Estate Pte Ltd','Exclusive Real Estate Pte Ltd','FER Properties Pte Ltd','Friendly Real Estate Agency Pte Ltd ','Gold-Link Realty Pte Ltd','Goodwill Consultant Company ','HJ Real Estate Pte Ltd',"Jany's Real Estate Pte Ltd",'Kensfield Housing Agency ','KPC Properties Pte Ltd','Land El-Home Pte Ltd','Landplus Property Network Pte Ltd','Landvin Pte Ltd','Lim Real Estate (Lin Shi Di Chan)','LJ Property Consultants','Master Real Estate Pte Ltd','Nixon Real Estate Pte Ltd','Parkland Real Estate Pte Ltd','Pegasus Property Solutions Pte Ltd','P M C Services Pte Ltd','Premier Homes Pte Ltd','Prolink Real Estate ','Propertybank Pte Ltd ','Property Master Realty Pte Ltd','REA Realty Network Pte Ltd','Ripton Realty Pte Ltd ','Senturion Real Estate Pte Ltd','Singapore Estate Agency Pte Ltd ','Singapore Realty Solutions Pte Ltd','Solid Rock Properties Pte Ltd','Territory Properties Pte Ltd ','Unison Agency Pte. Ltd. ','Unity Property Pte Ltd','Write Realty Pte Ltd','YES 21 Property Agents LLP','YES Property Pte Ltd ','Younique Property Consultants Pte Ltd','Your Estate Specialist LLP','Oriental Realty Pte Ltd','Strata AMC Pte.Ltd','Teakhwa Real Estate Pte Ltd','']

    return(
        <Grid container justifyContent='space-around' alignItems='center' spacing={3} sx={{width:'100%', marginLeft:'0px',marginBottom:'80px'}}>
        <Grid item xs={10} sm={4}>
            <Typography sx={{textAlign:{xs:'center', sm:'left'},marginBottom:'20px',fontSize:'80px',color:'primary.main', fontWeight:'bold',lineHeight:'1.2' }}> Our<span style={{color:'#D4AF37'}}> AC</span>  <br></br>Members</Typography>
            <Typography sx={{marginBottom:{xs:'20px',sm:'0px'}, textAlign:{xs:'center', sm:'left'},fontSize:'20px',color:'primary.typo' }}>Amidst slow and fiercely competitive market, Alliance Connect
                will continue to go above and beyond to deliver extraordinary
                service to our clients and associates. With a diverse portfolio
                and strong network support, we plan to penetrate various
                markets and strengthen our global presence.
            </Typography>

        </Grid>
        <Grid item xs={11} sm={7}>
            <Grid container sx={{backgroundColor:'#374049',paddingTop:'50px', paddingBottom:'50px', borderRadius:'30px'}} justifyContent='center' spacing={0}> 
            {ListOfAgencies.map((agency)=><Grid item xs={5}><Typography sx={{fontWeight:'bold',color:'customYellow.main',fontSize:'14px',textAlign:'left',marginBottom:"10px"}}>{agency}</Typography></Grid>)}
            
            </Grid>
        
        </Grid>

    </Grid>
    )
}
export default HomeListComponent