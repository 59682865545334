import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import logo from '../../storage/Assets/Homepage/Png_Logo.png'
const HomeBannerBox = ()=>{
return(
    <>
    <Box sx={{backgroundColor:'primary.main', opacity:{xs:'85%', sm:'100%'}, width:{xs:'90%', sm:522 },margin:{xs:'0 auto', sm:0}, height:{xs:260 ,sm:291}}}> 
        <Box sx={{m:'0 auto', width:'80%',pt:'20px'}}> 
        <Box sx={{display:'flex', justifyContent:{xs:'center',sm:'flex-start'}}}>
        <img src={logo} style={{width:'300px'}}></img>
        </Box>
        <Box sx={{display:{xs:'none', sm:'block'}, height:15,width:291,backgroundColor:'customYellow.main'}}> 
        </Box> 
        <Typography sx={{fontSize:{xs:'15px',sm:'20px'},fontWeight:'bold',textAlign:{xs:'center',sm:'left'}, color:'primary.secondary',mt:'5px'}}> 
        The platform to connect both businesses and consumers in the real estate market.
        </Typography>
        <Box sx={{display:{xs:'block', sm:'none'}, height:15,width:'100%',backgroundColor:'customYellow.main',mt:'10px', ml:0}}> </Box>
        </Box>
    </Box> 
    </>
)

}

export default HomeBannerBox