import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import navItems from './NavItems/index'
import web_logo from '../../storage/img/web_logo.png'
import mobile_logo from '../../storage/img/mobile_logo.png'
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/system';
import {
    useNavigate

} from "react-router-dom";
import { Paper } from '@mui/material';
import { LocationDisabledOutlined } from '@mui/icons-material';

const NavAppBar = (props) => {
    const [currentNav, setCurrentNav] = useState(0)
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleNavigate = (link, id) => {
        // setCurrentNav(id)
        navigate(`/${link}`);
    }
    let location = useLocation()

    const handleContactClick = (event) => {
       
        const anchor = (event.target.ownerDocument || document).querySelector(
            '#test-contact-scroll',
        );

        if (anchor) {
            
            anchor.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});;
        }
    };


    return (
        <AppBar component="nav" sx={{ height: 100 }} >
            <Toolbar id="back-to-top-anchor" sx={{height:'100%'}}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={props.handleDrawerToggle}
                    sx={{ mr: 0, display: { sm: 'none' } }}
                >
                    <MenuIcon />
                </IconButton>
                <Box nowrap sx={{
                    mr: 3,
                    display: { xs: 'flex', sm: 'none' },
                    flexGrow: 1,

                    letterSpacing: '.3rem',
                    textDecoration: 'none',
                    justifyContent: 'center'
                }}>

                    <img src={mobile_logo} width="60px"></img>

                </Box>
                <Box
                    variant="h6"
                    component="div"
                    sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
                    <img src={web_logo} width="150px" onClick={() => handleNavigate('')}></img>
                </Box>

                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                    {navItems.map((item) => {
                        if (item.child != null) {
                         

                            return (
                                <>
                                    <Button
                                        key={item.id}
                                        id="project-button"
                                        aria-controls={open ? 'project-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        variant="contained"
                                        disableElevation
                                        onClick={handleClick}
                                        endIcon={<KeyboardArrowDownIcon />}
                                        sx={{ borderBottom: location.pathname === '/Local_Projects' || location.pathname === '/International_Projects' ? '2px solid #D4AF37' : null, borderRadius: '0px' }}
                                    >
                                        {item.value}
                                    </Button>

                                    <Menu

                                        id="project-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'project-button',
                                        }}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                    >
                                        {item.child.map((child) => {
                                            return (
                                                <MenuItem onClick={() => {handleClose();handleNavigate(child.link, item.id)}} disableRipple id={child.value} key={child.id}>
                                                    {child.value}
                                                </MenuItem>
                                            )
                                        })}
                                    </Menu>

                                </>
                            )
                        }
                        else {
                            return (
                                <Button sx={{ borderBottom: item.pathName === location.pathname ? '2px solid #D4AF37' : null, borderRadius: '0px' }} variant="contained" disableElevation key={item.id} onClick={() => handleNavigate(item.link, item.id)}>
                                    {item.value}
                                </Button>
                            )
                        }

                    })}

                    <Button sx={{ ml: '20px', color: 'customYellow.main', borderColor: 'customYellow.main' }} variant="outlined" disableElevation onClick={handleContactClick}>
                        Enquire
                    </Button>
                </Box>
            </Toolbar>
        </AppBar>
    )
}

export default NavAppBar