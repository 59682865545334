import React, { useState } from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import HomePage1 from '../../storage/Assets/Homepage/HomePageImage1.jpg'
import HomePage2 from '../../storage/Assets/Homepage/HomePageImage5.jpg'
import HomePage3 from '../../storage/Assets/Homepage/HomePageImage4.jpg'

import Box from '@mui/material/Box';
import HomeBannerBox from './HomeBannerBox'
import HomeBannerButtons from './HomeBannerButtons'
import Stack from '@mui/material/Stack';
import HomeHeaderComponent from './HomeHeaderComponent'
import HomeCardsComponent from './HomeCardsComponent'
import HomeProjectShowCase from './HomeProjectShowCase'
import HomeNewsSection from './HomeNewsSection'
import HomeFormComponent from './HomeFormComponent'
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Fade from '@mui/material/Fade';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Footer from '../footer/Footer'
import ScrollTop from '../scrollTop/ScrollTop'
import AboutBanner from '../about/AboutBanner'
import AboutHeader from '../about/AboutHeader'
import HomeListComponent from './HomeLIstComponent';

const AlternateHome = (props) => {
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 1
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
    return (
        <React.Fragment>
           <Carousel 
             autoPlay={true}
             autoPlaySpeed={4000}
             infinite
             responsive={responsive}
            >
                <Box  sx={{ backgroundImage: `url(${HomePage1})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', height: { xs: 647, sm: 696 }, backgroundPosition: "50% 50%", width: "100%", pt: { xs: '100px', sm: '50px' }, pl: { xs: 0, sm: '50px' } }}>
                    <HomeBannerBox>
                    </HomeBannerBox>
                    <HomeBannerButtons>
                    </HomeBannerButtons>

                </Box>
                <Box sx={{ backgroundImage: `url(${HomePage2})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', height: { xs: 647, sm: 696 }, backgroundPosition: "50% 50%", width: "100%", pt: { xs: '100px', sm: '50px' }, pl: { xs: 0, sm: '50px' } }}>
                    <HomeBannerBox>
                    </HomeBannerBox>
                    <HomeBannerButtons>
                    </HomeBannerButtons>

                </Box>
                <Box sx={{ backgroundImage: `url(${HomePage3})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', height: { xs: 647, sm: 696 }, backgroundPosition: "50% 50%", width: "100%", pt: { xs: '100px', sm: '50px' }, pl: { xs: 0, sm: '50px' } }}>
                    <HomeBannerBox>
                    </HomeBannerBox>
                    <HomeBannerButtons>
                    </HomeBannerButtons>

                </Box>
          

            </Carousel>
            <Stack>
                <HomeHeaderComponent></HomeHeaderComponent>
                <HomeListComponent></HomeListComponent>
                <HomeCardsComponent></HomeCardsComponent>

                <AboutHeader></AboutHeader>

                <Box sx={{height:'1px', width:'1px'}} id="main2-contact-scroll"></Box>
                <HomeFormComponent></HomeFormComponent>
                <Footer></Footer>
            </Stack>


            <ScrollTop {...props}>
            </ScrollTop>

        </React.Fragment>
    )
}
export default AlternateHome