import ProjectBanner from './ProjectBanner'
import ProjectMultiCarousel from './ProjectMultiCarousel'
import Footer from '../footer/Footer'
import ContactForm from '../contact/ContactForm'
import ProjectShowcase from './ProjectShowcase'
import ScrollTop from '../scrollTop/ScrollTop'
const Main = (props) => {
    return (
        <>
            <ProjectBanner></ProjectBanner>
            <ProjectMultiCarousel></ProjectMultiCarousel>
            <ProjectShowcase type={props.type}></ProjectShowcase>
            <ContactForm></ContactForm>
            <Footer></Footer>
            <ScrollTop {...props}>
            </ScrollTop>

        </>
    )
}

export default Main

