import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { config } from '../../config/index'
import {Slide,Fade} from  "react-awesome-reveal";
import { useNavigate } from "react-router-dom";
const { DIRECTUS_API_ASSET_URL } = config
const NewsFeed = (props)=>{
    const navigate = useNavigate()
    const handleButtonClick = (id) => {
        navigate('/News/' + id)
    }

    return (
        <>
            <Stack>
              <Typography sx={{ width: 'fit-content', margin: '0 auto', fontSize: { xs: '15px', sm: '18px' }, fontWeight: 'bold', textDecoration: 'underline', color: 'primary.main' ,mt:'20px' }}>
                        NEWS FEED
            </Typography>
            <Fade triggerOnce={true} duration={1200}>
            <Grid container direction="row"
                justifyContent="left"
                spacing={8}
                sx={{ mb: '120px', mt: '10px' }}
            >
                {props.news.map((topNew) => <Grid item xs={12} sm={4} >  <Box sx={{ width: '90%', margin: '0 auto', height: '100%',backgroundColor:'primary.main', color:'white', border: '2px solid #083D21', borderRadius: '20px', pt: '30px' }}>
                    <Stack spacing={3} sx={{ width: '90%', margin: '0 auto', height: '100%' }}>
                        <img src={DIRECTUS_API_ASSET_URL + topNew.cover_image} style={{ width: '100%' }}></img>
                        <Typography sx={{ color: 'white', fontSize: '20px', fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'noWrap' }}>
                            {topNew.title}
                        </Typography>
                        <Typography sx={{color: 'white', fontSize: '16px' }}>
                            {topNew.short_description}
                        </Typography>


                        <Button variant="contained" onClick={()=>handleButtonClick(topNew.id)} sx={{ marginTop: 'auto !important', mr: '30px', width: 'fit-content', backgroundColor: 'white', color:'black', mb: '30px !important' }} >
                            View More
            </Button>

                    </Stack>
                </Box> </Grid>)}
            </Grid>
            </Fade>
            </Stack>
        </>
    )
}

export default NewsFeed