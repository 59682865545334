import React,{useState} from 'react'

const Contact = ()=>{

    return(
        <React.Fragment>
            contact
        </React.Fragment>
    )
}
export default Contact