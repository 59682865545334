import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import navItems from './NavItems/index'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import web_logo from '../../storage/img/web_logo.png'
import {
    useNavigate

} from "react-router-dom";
const drawerWidth = 240;

const NavDrawer = (props) => {
    const [open, setOpen] = React.useState(true);
    const navigate = useNavigate()
    const handleClick = () => {
        setOpen(!open);
    };
    const handleNavigate = (link, id) => {
        // setCurrentNav(id)
        navigate(`/${link}`);
    }
    const handleContactClick = (event) => {
       
        const anchor = (event.target.ownerDocument || document).querySelector(
            '#main2-contact-scroll',
        );

        if (anchor) {
            
            anchor.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});;
        }
    };
    return (
        <Drawer
            variant="temporary"
            open={props.mobileOpen}
            onClose={props.handleDrawerToggle}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}>

            <Box sx={{ textAlign: 'center' }}>
                <Box sx={{ backgroundColor: 'primary.main', pt: '20px', pb: '20px' }}>
                    <img src={web_logo} width="150px" onClick={() => handleNavigate('')}></img>
                </Box>


                <Divider />


                <List>
                    <List
                        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                    >
                        <ListItemButton  onClick={() => handleNavigate('', 1)}>
                            <ListItemText primaryTypographyProps={{
                                fontSize: 15,
                                fontWeight: 'bold',
                                color: 'primary.main',
                                textAlign: 'center'
                            }} primary="HOME" />
                        </ListItemButton>

                        <ListItemButton onClick={handleClick}>

                            <ListItemText primaryTypographyProps={{
                                fontSize: 15,
                                fontWeight: 'bold',
                                color: 'primary.main',
                                textAlign: 'center',
                                ml: '24px'
                            }} primary="PROJECTS" />
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton sx={{ pl: 4 }} onClick={() => handleNavigate('Local_Projects', 5)}>

                                    <ListItemText primaryTypographyProps={{
                                        fontSize: 15,
                                        fontWeight: 'bold',
                                        color: 'primary.main',
                                        textAlign: 'center'
                                    }} primary="LOCAL" />
                                </ListItemButton>
                            </List>
                            {/* <List component="div" disablePadding>
                                <ListItemButton sx={{ pl: 4 }}
                                 onClick={() => handleNavigate('International_Projects', 6)}
                                >

                                    <ListItemText primaryTypographyProps={{
                                        fontSize: 15,
                                        fontWeight: 'bold',
                                        color: 'primary.main',
                                        textAlign: 'center'
                                    }} primary="INTERNATIONAL" />
                                </ListItemButton>
                            </List> */}
                        </Collapse>
                        <ListItemButton  onClick={() => handleNavigate('Events', 2)}>

                            <ListItemText primaryTypographyProps={{
                                fontSize: 15,
                                fontWeight: 'bold',
                                color: 'primary.main',
                                textAlign: 'center'

                            }} primary="EVENTS" />
                        </ListItemButton>
                        <ListItemButton  onClick={() => handleNavigate('News', 3)}>

                            <ListItemText primaryTypographyProps={{
                                fontSize: 15,
                                fontWeight: 'bold',
                                color: 'primary.main',
                                textAlign: 'center'

                            }} primary="NEWS" />
                        </ListItemButton>
                    </List>



                    <Button sx={{ color: 'customYellow.main', borderColor: 'customYellow.main' }} variant="outlined" disableElevation onClick={handleContactClick}>
                        Enquire
                    </Button>
                </List>

            </Box>
        </Drawer>
    )
}

export default NavDrawer