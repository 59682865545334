import MultiCarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { config } from '../../config/index'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button'
import ProjectMultiCarouselBoxes from './ProjectMultiCarouselBoxes'
import { useContext } from 'react'
import { ProjectContext } from './ProjectContext'

const ProjectMultiCarousel = () => {
    const projects = useContext(ProjectContext).filter(project=>project.featured==true)
    
    const responsive = {
        desktop: {
          breakpoint: {
            max: 3000,
            min: 1024
          },
          items: 3,
          partialVisibilityGutter: 40
        },
        mobile: {
          breakpoint: {
            max: 464,
            min: 0
          },
          items: 1,
          partialVisibilityGutter: 0
        },
        tablet: {
          breakpoint: {
            max: 1024,
            min: 464
          },
          items: 1,
          partialVisibilityGutter: 0
        }
      }
    return (
        <>
            <Grid container direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{ width: '100%', mt: '120px', mb: '120px' }}
            >
                <Grid item xs={6}>
                    <Typography sx={{ fontSize: { xs: '15px', sm: '18px' }, fontWeight: 'bold', textDecoration: 'underline', color: 'customYellow.main', mb: '15px' }}>
                       FEATURED GALLERY
                    </Typography>

                </Grid>


            </Grid>
            {/* <Carousel  responsive={responsive}>
                  {projects.map((project)=><ProjectMultiCarouselBoxes project={project}></ProjectMultiCarouselBoxes>)}
                </Carousel> */}
            <Box sx={{mb:"120px"}}>
            
            <MultiCarousel
                additionalTransfrom={0}
                arrows
                autoPlay={true}
                autoPlaySpeed={4000}
                className=""
                containerClass="container"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                centerMode={false}
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                partialVisible={false}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={responsive}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable
            >
                {projects.map((project) => <ProjectMultiCarouselBoxes project={project}></ProjectMultiCarouselBoxes>)}
            </MultiCarousel>
            </Box>
        </>
    )
}
export default ProjectMultiCarousel