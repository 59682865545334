import { useParams } from "react-router-dom";
import React, {useState, useEffect} from 'react'
import {fetchSingleNews} from '../../../utils/Fetch'
import Loader from '../../Loader'
import Main from './Main'
const SingleNews=()=>{
    let [news, setNews] = useState()
    let [loading, setLoading] = useState(true)
    let params = useParams()
    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchSingleNews(params.id).catch(err => alert('network error, contact adminstrator'))
            setNews(response.data)
            setLoading(false)
            window.scrollTo(0, 0);
        }

        fetchData()
    }, [params])

    return(
        loading ? <Loader/> : <Main news = {news}></Main> 
    )
}

export default SingleNews
