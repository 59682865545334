import { config } from '../../config/index'
import FullCalendar from '@fullcalendar/react'
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import Box from '@mui/material/Box';

const Calender = (props) => {
    const { REACT_APP_GOOGLE_CALENDER_API } = config
    const handleClick=(e)=>{
        e.jsEvent.preventDefault(); // don't let the browser navigate
    console.log(e.event)
        
    if (e.event.url) {
      window.open(e.event.url);
    }
    }
    return (
        <>
           <Box sx={{display:{xs:'none',sm:'block'}}} > 
           <FullCalendar
                plugins={[dayGridPlugin, googleCalendarPlugin, timeGridPlugin, listPlugin]}
                googleCalendarApiKey={REACT_APP_GOOGLE_CALENDER_API}
                eventTimeFormat={{
                    hour: 'numeric',
                    minute: '2-digit',
                    meridiem: 'short'
                  }}
                events={{ googleCalendarId: "allianceconnectmgmt@gmail.com" }}
                headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,listWeek'
                }}
                editable={false}
                eventClick={(e)=>handleClick(e)}
          
      />
            </Box>
            <Box sx={{display:{xs:'block',sm:'none'}}}> 
            <FullCalendar
                plugins={[dayGridPlugin, googleCalendarPlugin, timeGridPlugin, listPlugin]}
                googleCalendarApiKey={REACT_APP_GOOGLE_CALENDER_API}
                initialView="listWeek"
                eventTimeFormat={{
                    hour: 'numeric',
                    minute: '2-digit',
                    meridiem: 'short'
                  }}
                events={{ googleCalendarId: "allianceconnectmgmt@gmail.com" }}
                headerToolbar={{
                    left: 'prev',
                    center: 'title',
                    right: 'next'
                }}
                editable={false}
                eventClick={(e)=>handleClick(e)}
          
      />
            </Box>
            
            {/* <Calendar apiKey={REACT_APP_GOOGLE_CALENDER_API} calendars={calendars} /> */}
        </>
    )
}


export default Calender

