const details = [
    {id:0, value:'Type', key:'type'},
    {id:1, value:'Total Units', key:'total_units'},
    {id:2, value:'Site Area', key:'site_area'},
    {id:3, value:'Exp TOP', key:'exp_top'},
    {id:4, value:'Address', key:'address'},
    {id:5, value:'Location', key:'location'},
    {id:6, value:'Tenure', key:'Tenure'},
    {id:7, value:'Country', key:'country'},
]

export {details}