import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import map from '../../storage/Assets/Homepage/map.png'
import teamwork from '../../storage/Assets/Homepage/teamwork.png'
import { Slide } from "react-awesome-reveal";
const HomeHeaderComponent = () => {
     return (
        <>
            <Box sx={{ width: { xs: '95%', sm: '45%' }, m: '0 auto', mt: '80px', mb: '120px', textAlign: 'center' }}>

                <Typography sx={{ fontSize: { xs: 18, sm: 35 }, fontWeight: 'bold', color: 'primary.main' }}>
                    One platform, endless potential
                </Typography>

                <Typography sx={{ fontSize: { xs: 12, sm: 20 }, fontWeight: 'light', color: 'primary.typo' }}>

                    Alliance Connect strife to provide one platform with the mission to make real estate brokerage businessses a transparent and seamless flow for B to B and B to C      </Typography>
                <Stack direction="row" spacing={12} mt={5} justifyContent="center">
                    <Slide direction="left" triggerOnce={true} duration={1200}>
                        <Box>
                            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                                <img style={{ width: '69px' }} src={teamwork} />
                            </Box>
                            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                                <img style={{ width: '45px' }} src={teamwork} />
                            </Box>
                            <Typography sx={{ fontSize: { xs: 13, sm: 20 }, fontWeight: 'regular', color: 'primary.typo' }}>
                                More than
                            </Typography>
                            <Typography sx={{ fontSize: { xs: 33, sm: 50 }, fontWeight: 'bold', color: 'customYellow.main' }}>
                                700
                            </Typography>
                            <Typography sx={{ fontSize: { xs: 13, sm: 20 }, fontWeight: 'regular', color: 'primary.typo' }}>
                                Real Estate Salespersons
                            </Typography>
                        </Box>
                    </Slide>
                    <Slide direction="right" triggerOnce={true} duration={1200}>
                        <Box>
                            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                                <img style={{ width: '87px' }} src={map} />
                            </Box>
                            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                                <img style={{ width: '57px' }} src={map} />
                            </Box>
                            <Typography sx={{ fontSize: { xs: 13, sm: 20 }, fontWeight: 'regular', color: 'primary.typo' }}>
                                Working with more than
                            </Typography>
                            <Typography sx={{ fontSize: { xs: 33, sm: 50 }, fontWeight: 'bold', color: 'customYellow.main' }}>
                                40
                            </Typography>
                            <Typography sx={{ fontSize: { xs: 13, sm: 20 }, fontWeight: 'regular', color: 'primary.typo' }}>
                                Licenced Estate Agents
                            </Typography>
                        </Box>
                    </Slide>
                </Stack>
            </Box>

       

        </>
    )


}

export default HomeHeaderComponent