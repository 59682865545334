import NewsFeed from './NewsFeed'
import NewsTopStories from './NewsTopStories'
import Contact from '../contact/ContactForm'
import Footer from '../footer/Footer'
import ScrollTop from '../scrollTop/ScrollTop'
const Main = (props)=>{
 return(
    <>
    <NewsTopStories news={props.news}></NewsTopStories>
    <NewsFeed news={props.news}></NewsFeed>
    <Contact></Contact>
    <Footer></Footer>
    <ScrollTop {...props}>
            </ScrollTop>
    </>
    )
}
export default Main