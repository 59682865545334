import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button'
import { useNavigate } from "react-router-dom";
import { config } from '../../config/index'
const { DIRECTUS_API_ASSET_URL } = config
const ProjectShowcaseBoxes = (props) => {
    const navigate = useNavigate()
    const handleButtonClick = (id) => {
        navigate('/Project/' + id)
    }

    props.project.description = props.project.description.substring(0, 175) + '...';


    const backgroundImg = DIRECTUS_API_ASSET_URL + props.project.cover_image
    return (
        <Box sx={{ width: '90%', margin: '0 auto', height: '100%',color:'white',backgroundColor:'#083D21',border: '2px solid #083D21', borderRadius: '20px', pt: '30px' }}>
            <Stack sx={{ width: '90%', margin: '0 auto', height: '100%' }}>
                <img src={backgroundImg} style={{ width: '100%' }}></img>
                <Typography sx={{ pt: '20px', pb: '20px', fontSize: '23px', fontWeight: 'bold' }}>
                    {props.project.name}
                </Typography>
                <Typography sx={{ fontSize: '16px' }}>
                    {props.project.description}
                </Typography>


                <Button variant="contained" sx={{ mt: 'auto', mr: '30px', width: 'fit-content',backgroundColor: 'white', color:'black', mb: '30px' }} onClick={() => handleButtonClick(`${props.project.id}`)}>
                    View More
            </Button>

            </Stack>
        </Box>
    )
}
export default ProjectShowcaseBoxes