import { config } from '../../config/index'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import Button from '@mui/material/Button'

import { useNavigate } from "react-router-dom";
const { DIRECTUS_API_ASSET_URL } = config
const ProjectBannerBox = (props) => {
    const navigate = useNavigate()
    const handleButtonClick = (link) => {
        navigate('/' + link)
    }
    const backgroundImg = DIRECTUS_API_ASSET_URL + props.project.project_banner_image

    return (
        <Box sx={{ backdropFilter: 'grayscale(50%)', backgroundImage: `url(${DIRECTUS_API_ASSET_URL + props.project.project_banner_image})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', height: { xs: 647, sm: 696 }, backgroundPosition: "50% 50%", width: "100%" }}>

            <Grid container justifyContent={{ xs: 'center', sm: 'flex-start' }} alignItems="center" sx={{ textAlign: 'left', height: '100%', pl: { xs: 0, sm: '50px' } }}>
                <Grid item xs={10} sm={7} >
                    <Stack>
                        <Typography sx={{ fontSize: { xs: '45px', sm: '55px' }, color: 'customYellow.main', fontWeight: 'bold' }}>
                            THE REAL ESTATE MARKETPLACE
                </Typography>
                        <Typography sx={{ width: { sx: '100%', sm: '50%' }, fontSize: { xs: '18px', sm: '25px' }, color: 'primary.secondary', fontWeight: 'regular' }}>
                            The one-stop platform for local & international property developments.
                    </Typography>
                        <Box sx={{ width: 'fit-content', m: { xs: '0 auto', sm: '0 0' } }}>
                            <Stack spacing={2} sx={{ mt: '30px' }}>
                                <Button variant="contained" sx={{ width: { xs: 296, sm: 369 }, backgroundColor: 'customYellow.main' }} onClick={() => handleButtonClick('Local_Projects')}>
                                    Local Projects
                                </Button>
                                {/* <Button variant="contained" sx={{ width: { xs: 296, sm: 369 }, backgroundColor: 'customYellow.main' }} onClick={() => handleButtonClick('International_Projects')}>
                                    International Projects
                                 </Button> */}

                            </Stack>
                        </Box>
                    </Stack>

                </Grid>

            </Grid>
            <Button variant="contained" sx={{mt:'50px',mr:'30px', width: 'fit-content', backgroundColor: 'customYellow.main',position:'absolute', top:0, right:0  }} onClick={()=>window.open(props.project.project_link, "_blank")}>
            {props.project.project_name}
            </Button>
            
        </Box>
        
    )
}

export default ProjectBannerBox