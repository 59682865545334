import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button'
import ProjectMultiCarouselBoxes from './ProjectMultiCarouselBoxes'
import { useContext } from 'react'
import { ProjectContext } from './ProjectContext'
import ProjectShowcaseBoxes from './ProjectShowcaseBoxes';
import {Slide,Fade} from  "react-awesome-reveal";
const ProjectShowcase = (props) => {
    const projects = useContext(ProjectContext)
    return (
        <Grid container direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ width: '100%', mt: '120px', mb: '120px' }}
        >
            <Grid item xs={6}>
                <Typography sx={{ fontSize: { xs: '15px', sm: '18px' }, fontWeight: 'bold', textDecoration: 'underline', color: 'primary.typo' }}>
                        LOCAL PROJECTS
                    </Typography>

            </Grid>
            <Grid item>

                <Grid container direction='row' spacing={2} sx={{ width: '95%', margin: '0 auto', mt: '80px', mb: '80px' }}>
                    <Grid item xs={12}>
                    <Slide direction="left" triggerOnce={true} duration={1200}>
                        <Typography sx={{ fontSize: '28px', color: 'primary.main', fontWeight: 'bold' }}>
                          LOCAL PROJECTS
                    </Typography>
                    </Slide>
                    </Grid>
                    <Grid item xs={12}>
                    <Slide direction="left" triggerOnce={true} duration={1200}>
                        <Typography sx={{ fontSize: '20px', color: 'primary.typo' }}>
                            Alliance Connect provides One platform with the mission to make the real estate brokerage business a better place for B to B & B to C.  Strategically. Seamlessly. For Both the Developers and the Real Estate Salespersons.
                    </Typography>
                    </Slide>
                    </Grid>

                </Grid>
            </Grid>
            <Slide direction="left" triggerOnce={true} duration={1200}>
           
                <Grid container direction="row"
                    justifyContent="left"
                    spacing={12}
                   >
                    {projects.map((project) => <Grid item xs={12} sm={4} ><ProjectShowcaseBoxes project={project}></ProjectShowcaseBoxes> </Grid>)}
                </Grid>

           
    </Slide>

        </Grid>
    )
}
export default ProjectShowcase