import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
const HomeBannerButtons = ()=>{
    const navigate = useNavigate()
    const handleButtonClick = (link)=>{
        navigate('/'+link)
    }
return(
    <Box sx={{width:'fit-content', m:{xs:'0 auto',sm:'0 0'}}}>
    <Stack spacing={2} sx={{mt:'30px'}}> 
        <Button variant="contained" sx={{width:{xs:296, sm:369},height:'45px',  fontWeight:'bold', backgroundColor:'customYellow.main'}} onClick={()=>handleButtonClick('Local_Projects')}> 
        VIEW OUR PROJECTS
        </Button>
        <Button variant="contained" sx={{width:{xs:296, sm:369},height:'45px', fontWeight:'bold', backgroundColor:'customYellow.main'} } onClick={()=>handleButtonClick('News')}> 
        READ OUR NEWS
        </Button>
        <Button variant="contained" sx={{width:{xs:296, sm:369},height:'45px', fontWeight:'bold', backgroundColor:'customYellow.main'}} onClick={()=>handleButtonClick('Events')}> 
        FIND US AT OUR EVENTS
        </Button>
    </Stack>
    </Box>
)
}

export default HomeBannerButtons