import React from "react";
import emailjs from '@emailjs/browser';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import email from '../../storage/Assets/Homepage/email.png'
import {Fade,Slide} from  "react-awesome-reveal";
import { config } from '../../config/index'
import Swal from 'sweetalert2'
const {  REACT_APP_EMAILJS_SERVICE_ID, REACT_APP_EMAILJS_TEMPLATE_ID,REACT_APP_EMAILJS_PUBLIC_KEY} = config
const ContactSchema = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().email().required(),
    message: yup.string().required()
});

const ContactForm = () => {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(ContactSchema)
    });
    const onSubmit = (data) => {

    emailjs.send(REACT_APP_EMAILJS_SERVICE_ID, REACT_APP_EMAILJS_TEMPLATE_ID, data, REACT_APP_EMAILJS_PUBLIC_KEY)
      .then((result) => {
        Swal.fire('Thanks for contacting AllianceConnect, we will get back to you as soon as possible')
      }, (error) => {
          console.log(error.text);
      });
        
    };

    return (
    <Grid container  justifyContent="space-around"
    alignItems="center"
    sx={{mb:'120px'}}
    id="main-contact-scroll">
    <Grid item xs ={11} sm={5}> 
    <Slide direction='left' duration={1200} triggerOnce={true}> 
        <Grid container direction='column'>
        <Grid item > 
            
            <Typography sx={{fontSize:{xs:'35px', sm:'35px'},fontWeight:'medium',mb:'15px', color:'primary.main'}}> 
                Reach out to us
            </Typography>
            
            </Grid>
            <Grid item > 
            <Typography sx={{fontSize:{xs:'12px', sm:'15px'},mb:'10px',fontWeight:'light', color:'primary.typo'}}> 
            We will need some details so that we can address your inquiry accordingly. We will get back to you as soon as possible.
            </Typography>
            </Grid>
            <Grid item > 
            <Typography sx={{fontSize:{xs:'12px', sm:'15px'},mb:'5px',fontWeight:'light', color:'primary.typo'}}> 
            Subsequently, you can email us at:
            </Typography>
            </Grid>
            <Grid item > 
            <Typography sx={{fontSize:{xs:'12px', sm:'15px'},fontWeight:'medium', color:'customYellow.main'}}> 
            <Box>
                <img src={email} style={{marginRight:'10px'}}></img>
                allianceconnectmgmt@gmail.com
            </Box>
            </Typography>
            </Grid>
        </Grid> 
        </Slide>
    </Grid> 

        <Grid item xs={11} sm={5}>
        <Slide direction='right' duration={1200} triggerOnce={true}> 
        <form id="form" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label>Name</label>
          <input id="name" {...register("name")} />
          {errors.name && <p style={{color:'red',marginTop:'5px'}}>{errors.name.message}</p>}
        </div>
        <div>
          <label>Email</label>
          <input id="email" {...register("email")} />
          {errors.email && <p style={{color:'red',marginTop:'5px'}}>{errors.email.message}</p>}
        </div>
        <div style={{ marginBottom: 10 }}>
          <label>Message</label>
          <textarea id="message" {...register("message")} rows='10' style={{width:'100%'}}/>
          {errors.message && <p style={{color:'red',marginTop:'5px'}}>{errors.message.message}</p>}
        </div>
     
      
        <Button variant="contained" type='submit' sx={{ width: '100%', backgroundColor: 'customYellow.main', mt: '15px' }} >
        Submit
        </Button>
      </form>
      </Slide>
        </Grid>
    </Grid>

      
    );
}

export default ContactForm
